// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-admin-index-js": () => import("./../../../src/admin/index.js" /* webpackChunkName: "component---src-admin-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jackson-js": () => import("./../../../src/pages/jackson.js" /* webpackChunkName: "component---src-pages-jackson-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-wedding-js": () => import("./../../../src/pages/wedding.js" /* webpackChunkName: "component---src-pages-wedding-js" */),
  "component---src-templates-blog-post-custom-js": () => import("./../../../src/templates/blog-post-custom.js" /* webpackChunkName: "component---src-templates-blog-post-custom-js" */)
}

