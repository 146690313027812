/*import "@react-page/core/lib/index.css";
import "@react-page/ui/lib/index.css";
import "@react-page/plugins-slate/lib/index.css";
import "@react-page/plugins-background/lib/index.css";*/

// custom typefaces
import "typeface-montserrat";
import "typeface-merriweather";
import "typeface-parisienne"
import "typeface-damion"
import "typeface-roboto"
import "typeface-architects-daughter"
import "typeface-gochi-hand"
// normalize CSS across browsers
import "./src/normalize.css";
// custom CSS styles
import "./src/style.css";

// Highlighting for code blocks
import "prismjs/themes/prism.css";
import "./src/ckcontent-styles.css";
